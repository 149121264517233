import React from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  upperBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

const ChildrenContent = ({ children }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.upperBlock}>
          <CsvDownloader
            filename="children"
            separator=";"
            datas={children}
            text="DOWNLOAD"
            columns={[
              {
                id: 'first_name',
                displayName: 'First Name',
              },
              {
                id: 'last_name',
                displayName: 'Last Name',
              },
              { id: 'ID', displayName: 'ID' },
              { id: 'parent_id', displayName: 'Parent ID' },
              {
                id: 'age',
                displayName: 'Age',
              },
              { id: 'gender', displayName: 'Gender' },
              { id: 'created', displayName: 'Created' },
            ]}
          >
            <Button>Export in CSV</Button>
          </CsvDownloader>
          <Button
            color="secondary"
            variant="contained"
            style={{ backgroundColor: '#275262', color: 'white' }}
            onClick={() => history.push('/children/addchild')}
          >
            Add Child
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title="Children"
          columns={[
            { title: 'First Name', field: 'first_name' },
            { title: 'Last Name', field: 'last_name' },
            { title: 'ID', field: 'ID' },
            { title: 'Age', field: 'age' },
            { title: 'Gender', field: 'gender' },
            // { title: 'Medical Condition', field: 'medical_condition' },
            {
              title: 'Created',
              field: 'created',
            },
          ]}
          options={{ pageSize: 10 }}
          data={children}
          onRowClick={(e, rowData) => {
            history.push(`/children/${rowData.ID}`);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ChildrenContent;

import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { apiReducer } from './apiReducer';
import { tableReducer } from './tableReducer';

export default combineReducers({
  authReducer: authReducer,
  apiReducer: apiReducer,
  tableReducer: tableReducer,
});

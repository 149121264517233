import React, { memo } from 'react';
import DashboardContentLayout from '../DashboardContentLayout';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  buttonBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    '& > :first-child': {
      margin: '0 0 20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      flexDirection: 'row',
      maxWidth: '300px',
      '& > :first-child': {
        margin: '0 20px 0 0 ',
        maxWidth: '300px',
      },
    },
  },
  textFieldWrapper: {
    paddingBottom: theme.spacing(2),
  },
}));

const AddParentContent = ({
  parent,
  setName,
  setCity,
  setEmail,
  setCountry,
  setAdmin,
  setPass,
  setAddressOne,
  setAddressTwo,
  handleAddParent,
  handleCancelAddingParent,
}) => {
  const classes = useStyles();
  const { name, city, country, email, pass, addressOne, addressTwo } = parent;

  return (
    <DashboardContentLayout>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={8}>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Name"
              onChange={e => setName(e.target.value)}
              variant="outlined"
              value={name}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              autoComplete="off"
              label="Email"
              onChange={e => setEmail(e.target.value)}
              variant="outlined"
              value={email}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              type="password"
              autoComplete="off"
              label="Password"
              onChange={e => setPass(e.target.value)}
              variant="outlined"
              value={pass}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="City"
              variant="outlined"
              onChange={e => setCity(e.target.value)}
              value={city}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Country"
              variant="outlined"
              onChange={e => setCountry(e.target.value)}
              value={country}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Address Line 1"
              variant="outlined"
              onChange={e => setAddressOne(e.target.value)}
              value={addressOne}
            />
          </Grid>
          <Grid item xs={12} lassName={classes.textFieldWrapper}>
            <TextField
              fullWidth
              label="Address Line 2"
              variant="outlined"
              onChange={e => setAddressTwo(e.target.value)}
              value={addressTwo}
            />
          </Grid>
          {/* <Grid item xs={12} className={classes.textFieldWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={admin}
                  disabled={true}
                  // onChange={e => setAdmin(prevState => !prevState)}
                  color="primary"
                />
              }
              label="Admin"
            />
          </Grid> */}
        </Grid>
        <Grid item xs={4} className={classes.buttonBlock}>
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            style={{ backgroundColor: '#1D7A69', color: 'white' }}
            onClick={handleCancelAddingParent}
          >
            {' '}
            Clear Fields
          </Button>
          <Button
            fullWidth
            variant="contained"
            style={{ backgroundColor: '#275262', color: 'white' }}
            onClick={handleAddParent}
          >
            Add Parent
          </Button>
        </Grid>
      </Grid>
    </DashboardContentLayout>
  );
};

export default memo(AddParentContent);

import { LOG_OUT, TOKEN_OBTAINED } from '../types';

const initialState = {
  hasToken: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_OUT: {
      localStorage.removeItem('token');
      return {
        ...state,
        hasToken: false,
      };
    }
    case TOKEN_OBTAINED: {
      return {
        ...state,
        hasToken: true,
      };
    }
    default:
      return state;
  }
};

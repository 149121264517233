import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { setCurrentPage, setCurrentTable } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
  upperBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

const ParentsContent = props => {
  const history = useHistory();
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const { currentPage, currentTable } = useSelector(
  //   store => store.tableReducer
  // );

  // useEffect(() => {
  //   dispatch(setCurrentTable('parents'));
  // }, []);

  // const handlePageChange = p => {
  //   dispatch(setCurrentPage(p));
  // };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.upperBlock}>
          <CsvDownloader
            filename="parents"
            separator=";"
            datas={props.parents}
            text="DOWNLOAD"
            columns={[
              {
                id: 'name',
                displayName: 'Name',
              },
              { id: 'id', displayName: 'ID' },
              { id: 'email', displayName: 'Email' },
              {
                id: 'address1',
                displayName: 'Address 1',
              },
              {
                id: 'address2',
                displayName: 'Address 2',
              },
              { id: 'country', displayName: 'Country' },
              { id: 'created', displayName: 'Created' },
            ]}
          >
            <Button>Export in CSV</Button>
          </CsvDownloader>
          <Button
            color="secondary"
            variant="contained"
            style={{ backgroundColor: '#275262', color: 'white' }}
            onClick={() => history.push('/parents/addparent')}
          >
            Add Parent
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title="Parents"
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'ID', field: 'id' },
            { title: 'Email', field: 'email' },
            { title: 'City', field: 'city' },
            {
              title: 'Created',
              field: 'created',
            },
          ]}
          options={{
            pageSize: 10,
          }}
          data={props.parents}
          onRowClick={(e, rowData) => {
            history.push(`/parents/${rowData.id}`);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ParentsContent;

import React, { useState, useEffect, memo } from 'react';
import DashboardLayout from '../../../components/DashboardLayout';
import DashboardContentLayout from '../../../components/DashboardContentLayout';
import AddParentContent from '../../../components/AddParentContent';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addParent } from '../../../redux/actions';
import Loader from '../../../components/Loader';
import Typography from '@material-ui/core/Typography';

const AddParent = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const breadcrumbLinks = [{ route: '/parents', title: 'Parents' }];
  // const [editingParent, setEditingParent] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [country, setCountry] = useState('');
  const [medicalCondition, setMedicalCondition] = useState('');
  // const [admin, setAdmin] = useState(false);

  const clearAllFields = () => {
    setName('');
    setCity('');
    setEmail('');
    setAddressOne('');
    setAddressTwo('');
    setCountry('');
    setPass('');
  };

  const handleAddParent = () => {
    dispatch(
      addParent({
        name,
        addressOne,
        addressTwo,
        city,
        email,
        pass,
        country,
      })
    );
  };

  return (
    <DashboardLayout
      breadcrumbLinks={breadcrumbLinks}
      currentPage={'Add Parent'}
    >
      <ToastContainer />
      <AddParentContent
        parent={{
          name,
          city,
          country,
          email,
          pass,
          addressOne,
          addressTwo,
        }}
        setEmail={setEmail}
        setName={setName}
        setCity={setCity}
        // setAdmin={setAdmin}
        setCountry={setCountry}
        setPass={setPass}
        setAddressOne={setAddressOne}
        setAddressTwo={setAddressTwo}
        handleAddParent={handleAddParent}
        handleCancelAddingParent={clearAllFields}
      />
    </DashboardLayout>
  );
};

export default memo(AddParent);

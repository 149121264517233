import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  spinnerDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  text: {
    margin: theme.spacing(2),
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Fade
      in={true}
      style={{
        transitionDelay: '500ms',
      }}
      unmountOnExit
    >
      <div className={classes.spinnerDiv}>
        <CircularProgress size={50} />
        <Typography
          className={classes.text}
          data-testid="fetching-data-spinner"
        >
          Fetching data
        </Typography>
      </div>
    </Fade>
  );
};

export default Loader;

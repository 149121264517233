import { SET_CURRENT_PAGE, SET_CURRENT_TABLE } from '../types';

// NOT USED, cause material-table seems to not have a functionality of rendering a specific page :(

const initialState = {
  currentPage: null,
  currentTable: null,
};

export const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case SET_CURRENT_TABLE: {
      return {
        ...state,
        currentTable: action.payload,
      };
    }
    default:
      return state;
  }
};

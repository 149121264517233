import jwt_decode from 'jwt-decode';

export const getToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  const decodedJwt = jwt_decode(token);
  const currentTime = new Date().getTime();
  if (currentTime > decodedJwt.expires) {
    return null;
  }
  return token;
};

export const prettifyDate = dateInUTC => {
  if (!dateInUTC) {
    return 'not available';
  } else {
    return dateInUTC.split('T')[0];
  }
};

export const isPasswordStrong = pass => {
  return pass.length > 5;
};

export const isValidEmail = email => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};

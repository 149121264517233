import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import EqualizerIcon from '@material-ui/icons/Equalizer';

export const routes = [
  {
    title: 'Parents',
    route: '/parents',
    icon: () => <GroupIcon style={{ color: 'white' }} />,
  },
  {
    title: 'Children',
    route: '/children',
    icon: () => <PersonIcon style={{ color: 'white' }} />,
  },
  // {
  //   title: 'Reports',
  //   route: '/reports',
  //   icon: () => <EqualizerIcon style={{ color: 'white' }} />,
  // },
];

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllParents } from '../../redux/actions';
import Loader from '../../components/Loader';
import DashboardLayout from '../../components/DashboardLayout';
import DashboardContentLayout from '../../components/DashboardContentLayout';
import ParentsContent from '../../components/ParentsContent';

const Dashboard = () => {
  const dispatch = useDispatch();
  const parents = useSelector(store => store.apiReducer.parents);

  useEffect(() => {
    if (!parents) {
      dispatch(getAllParents());
    }
  }, []);

  if (!parents) {
    return (
      <DashboardLayout breadcrumbLinks={null} currentPage={'Parents'}>
        <DashboardContentLayout>
          <Loader />
        </DashboardContentLayout>
      </DashboardLayout>
    );
  } else {
    return (
      <div>
        <DashboardLayout breadcrumbLinks={null} currentPage={'Parents'}>
          <DashboardContentLayout>
            <ParentsContent parents={parents} />
          </DashboardContentLayout>
        </DashboardLayout>
      </div>
    );
  }
};

export default Dashboard;

import React, { Component } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

class ErrorWrapper extends Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    return hasError ? (
      <DashboardLayout currentPage={'Something went wrong'}>
        <Box
          style={{
            maxWidth: '500px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gridGap: '20px',
          }}
        >
          <Typography variant="body1">Something went wrong</Typography>
          <Link to="/parents" style={{ textDecoration: 'none' }}>
            <Button
              style={{
                backgroundColor: '#275262',
                color: 'white',
                maxWidth: '200px',
                margin: 'auto',
              }}
            >
              Back to main page
            </Button>
          </Link>
        </Box>
      </DashboardLayout>
    ) : (
      children
    );
  }
}
export default ErrorWrapper;

import {
  GET_PARENTS,
  GET_CHILDREN_FOR_PARENT,
  GET_CHILDREN,
  GET_CHILD_STARS_LOG,
  GET_CHILD_STORY_LOG,
  FIND_PARENT,
  UPDATE_PARENT,
  ADD_PARENT,
  ADD_CHILD,
} from '../types';

const initialState = {
  parents: null,
  children: null,
};

export const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARENTS: {
      return {
        ...state,
        parents: action.payload,
      };
    }
    case GET_CHILDREN: {
      return {
        ...state,
        children: action.payload,
      };
    }
    case GET_CHILDREN_FOR_PARENT: {
      if (state.parents === null) {
        return state;
      }
      const parentsChildren = { children: action.children };
      let selectedParent = state.parents.find(
        parent => parent.id == action.parentId
      );
      selectedParent = { ...parentsChildren, ...selectedParent };
      return {
        ...state,
        parents: state.parents.map(parent =>
          parent.id == action.parentId ? selectedParent : parent
        ),
      };
    }
    case GET_CHILD_STARS_LOG: {
      if (state.children === null) {
        return state;
      }
      const starsLog = { stars: action.stars };
      let selectedChild = state.children.find(
        child => child.ID == action.childId
      );
      selectedChild = { ...starsLog, ...selectedChild };
      return {
        ...state,
        children: state.children.map(child =>
          child.ID == action.childId ? selectedChild : child
        ),
      };
    }
    case GET_CHILD_STORY_LOG: {
      if (state.children === null) {
        return state;
      }

      const storyLog = { history: action.storyLog };
      let selectedChild = state.children.find(
        child => child.ID == action.childId
      );
      selectedChild = { ...storyLog, ...selectedChild };
      return {
        ...state,
        children: state.children.map(child =>
          child.ID == action.childId ? selectedChild : child
        ),
      };
    }
    case FIND_PARENT: {
      if (state.children === null) {
        return state;
      }
      const parentData = { parentData: action.parentData };
      let selectedChild = state.children.find(
        child => child.ID == action.childId
      );
      selectedChild = { ...parentData, ...selectedChild };
      return {
        ...state,
        children: state.children.map(child =>
          child.ID == action.childId ? selectedChild : child
        ),
      };
    }
    // case UPDATE_PARENT: {
    //   if (!state.parents) {
    //     return state;
    //   }
    //   let selectedParent = state.parents.find(parent => parent.id == action.id);
    //   selectedParent = action.parent;
    //   return {
    //     ...state,
    //     parents: state.parents.map(parent =>
    //       parent.id == action.id ? { ...parent, ...selectedParent } : parent
    //     ),
    //   };
    // }
    default:
      return state;
  }
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2),
  },
}));

const ChildrenList = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Children</Typography>
      </Grid>
      <Grid item xs={12}>
        <List className={classes.list}>
          {children.map(child => (
            <>
              <ListItem button component={Link} to={`/children/${child.ID}`}>
                <ListItemText primary={child.first_name} />
              </ListItem>
            </>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ChildrenList;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../Loader';

const useStyles = makeStyles(theme => ({
  content: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    [theme.breakpoints.up('sm')]: {
      minHeight: '550px',
    },
  },
}));

const PageLayout = props => {
  const classes = useStyles();

  return (
    <Fade in={true} unmountOnExit>
      <Card>
        <CardContent className={classes.content}>
          {props.children ? props.children : <Loader />}
        </CardContent>
      </Card>
    </Fade>
  );
};

export default PageLayout;

import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Breadcrumbs } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import logo from '../../images/logo.png';
import { useLocation, useHistory } from 'react-router';
import { routes } from './routes.js';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logOut } from '../../redux/actions';
import Loader from '../Loader';

const drawerWidth = 240;

// colors: '#009C8C', '#1D7A69', '#5FB993', '#275262'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: 'white',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  toolbarLeftBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRightBlock: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logoWrapper: {
    width: '100%',
    margin: 'auto',
    maxWidth: '50px',
  },
  logo: {
    width: '100%',
    maxWidth: '50px',
    margin: '-30px auto 0 auto',
    objectFit: 'contain',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#1D7A69',
    color: 'white',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const DashboardLayout = props => {
  const { window, breadcrumbLinks, currentPage } = props;
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    dispatch(logOut());
    history.push('/login');
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.logoWrapper}>
        <img src={logo} alt="Zimizam" className={classes.logo} />
      </div>
      <List>
        {routes.map(({ title, route, icon }, index) => (
          <ListItem
            button
            key={title}
            to={route}
            component={Link}
            selected={
              location.pathname === route || location.pathname.includes(route)
            }
          >
            <ListItemIcon>{icon()}</ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.toolbarContent}>
            <div className={classes.toolbarLeftBlock}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon style={{ color: '#424242' }} />
              </IconButton>
              <Breadcrumbs aria-label="Breadcrumb">
                {breadcrumbLinks &&
                  breadcrumbLinks.map((link, i) => (
                    <Link
                      to={link.route}
                      key={i}
                      style={{ textDecoration: 'none', color: '#275262' }}
                    >
                      {link.title}
                    </Link>
                  ))}
                <Typography style={{ color: '#424242' }} color="textPrimary">
                  {currentPage}
                </Typography>
              </Breadcrumbs>
            </div>
            <div className={classes.toolbarRightBlock} onClick={handleLogout}>
              <ExitToAppIcon style={{ color: '#424242' }} />
              <Hidden xsDown>
                {' '}
                <Typography variant="body1" style={{ color: '#424242' }}>
                  Log out
                </Typography>
              </Hidden>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children ? props.children : <Loader />}
      </main>
    </div>
  );
};

export default DashboardLayout;

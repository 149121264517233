import React, { useState, useEffect, useCallback, memo } from 'react';
import DashboardLayout from '../../../components/DashboardLayout';
import DashboardContentLayout from '../../../components/DashboardContentLayout';
import ChildPageContent from '../../../components/ChildPageContent';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'debounce-promise';
import { useLocation, useHistory } from 'react-router';
import {
  getAllChildren,
  getChildStoryLog,
  getChildStarsLog,
  findParent,
  updateChild,
} from '../../../redux/actions';
import { prettifyDate } from '../../../utils';
import Loader from '../../../components/Loader';
import star_blue from '../../../images/star_blue.png';
import star_gold from '../../../images/star_gold.png';
import star_purple from '../../../images/star_purple.png';
import star_green from '../../../images/star_green.png';
import star_grey from '../../../images/star_grey.png';
import star_orange from '../../../images/star_orange.png';
import star_pink from '../../../images/star_pink.png';
import star_red from '../../../images/star_red.png';
import star_teal from '../../../images/star_teal.png';

// move to utils
export const findChildData = (children, id) => {
  return children.find(kid => kid.ID == id);
};

export const getAverageGameDuration = durations => {
  const allTimePlayed = durations.reduce((a, b) => a + b, 0);
  const averageGameDuration = (allTimePlayed / 60 / durations.length).toFixed(
    2
  );
  if (averageGameDuration < 1) {
    return (averageGameDuration * 60).toFixed(2) + ' seconds';
  }
  return averageGameDuration + ' minutes';
};

export const calculateTotalStarsEarned = stars => {
  return stars.reduce((a, b) => a + b, 0);
};

export const genderOptions = ['male', 'female'];

export const mapStarsDataToView = star => {
  switch (star) {
    case 1:
      return star_grey;
    case 2:
      return star_green;
    case 3:
      return star_teal;
    case 4:
      return star_blue;
    case 5:
      return star_purple;
    case 6:
      return star_pink;
    case 7:
      return star_red;
    case 8:
      return star_orange;
    case 9:
      return star_gold;
    default:
      return star_grey;
  }
};

const ChildPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const selectedChildId = pathname.split('/children/')[1];
  const children = useSelector(store => store.apiReducer.children);
  const [selectedChild, setSelectedChild] = useState(null);
  const [readyToRender, setReadyToRender] = useState(false);
  const breadcrumbLinks = [{ route: '/children', title: 'Children' }];
  const [editingChild, setEditingChild] = useState(false);
  const [parentId, setParentId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [medicalCondition, setMedicalCondition] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [parentData, setParentData] = useState({});

  useEffect(() => {
    if (!children) {
      dispatch(getAllChildren());
      return;
    }

    if (children.length) {
      setSelectedChild(findChildData(children, selectedChildId));
    }
  }, [children, selectedChildId]);

  useEffect(() => {
    if (selectedChild) {
      if (!selectedChild.history) {
        dispatch(getChildStoryLog(selectedChildId));
      }
      if (!selectedChild.stars) {
        dispatch(getChildStarsLog(selectedChildId));
      }
      if (!selectedChild.parentData) {
        dispatch(findParent(selectedChild.parent_id, selectedChild.ID));
      }
      setFirstName(selectedChild.first_name);
      setLastName(selectedChild.last_name);
      setAge(selectedChild.age);
      setGender(selectedChild.gender);
      setParentId(selectedChild.parent_id);
      setMedicalCondition(selectedChild.medical_condition);
      setParentData(selectedChild.parentData);
    }

    if (
      selectedChild !== null &&
      selectedChild.stars &&
      selectedChild.history &&
      selectedChild.parentData
    ) {
      setReadyToRender(true);
    } else {
      setReadyToRender(false);
    }
  }, [selectedChild]);

  const handleGetAverageTimePlayed = history => {
    if (!history || !history.rows) {
      return 0;
    }
    const durations = history.rows.map(row => Number(row.game_duration));
    return getAverageGameDuration(durations);
  };

  const handleGetTotalStarsEarned = stars => {
    if (!stars || !readyToRender) {
      return 0;
    }
    const starsEarned = stars?.map(star => star.amount);
    return calculateTotalStarsEarned(starsEarned);
  };

  const handleUpdateChild = () => {
    dispatch(
      updateChild(selectedChildId, {
        id: selectedChild.ID,
        firstName,
        lastName,
        parentId,
        gender,
        age,
      })
    );
    // history.push('/children');
  };

  if (!children || !selectedChild || !readyToRender) {
    return (
      <DashboardLayout breadcrumbLinks={breadcrumbLinks} currentPage="Child...">
        <Loader />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      breadcrumbLinks={breadcrumbLinks}
      currentPage={`${selectedChild.first_name} ${selectedChild.last_name}`}
    >
      <ToastContainer />
      {readyToRender && (
        <ChildPageContent
          child={{
            firstName,
            lastName,
            age,
            gender,
            medicalCondition,
            parentId,
            parentData,
          }}
          totalStarsEarned={handleGetTotalStarsEarned(selectedChild.stars)}
          lastTimeModified={selectedChild.last_modified}
          stars={
            selectedChild.stars
              ? selectedChild.stars.map(star => {
                  return {
                    img: mapStarsDataToView(star.star_id),
                    total: star.amount,
                  };
                })
              : []
          }
          averageGameDuration={handleGetAverageTimePlayed(
            selectedChild.history
          )}
          genderOptions={genderOptions}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setAge={setAge}
          setGender={setGender}
          setMedicalCondition={setMedicalCondition}
          editingChild={editingChild}
          setEditingChild={setEditingChild}
          handleUpdateChild={handleUpdateChild}
        />
      )}
    </DashboardLayout>
  );
};

export default memo(ChildPage);

import Login from './pages/Login';
import ParentsPage from './pages/Parents';
import ParentPage from './pages/Parents/ParentPage';
import AddParent from './pages/Parents/AddParent';
import AddChild from './pages/Children/AddChild';
import ChildrenPage from './pages/Children';
import ChildPage from './pages/Children/ChildPage';
import PrivateRoute from './components/PrivateRoute';
import ErrorWrapper from './pages/ErrorWrapper';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import { getToken } from './utils';

const App = () => {
  const token = getToken();

  return (
    <ErrorWrapper>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/"
          render={() => {
            if (token) {
              return <Redirect to="/parents" />;
            } else {
              return <Redirect to="/login" />;
            }
          }}
        />
        <PrivateRoute exact path="/parents" component={ParentsPage} />
        <PrivateRoute exact path="/parents/addparent" component={AddParent} />
        <PrivateRoute exact path="/parents/:id" component={ParentPage} />
        <PrivateRoute exact path="/children" component={ChildrenPage} />
        <PrivateRoute exact path="/children/addchild" component={AddChild} />
        <PrivateRoute exact path="/children/:id" component={ChildPage} />
      </Switch>
    </ErrorWrapper>
  );
};

export default App;

import React, { useState, useEffect, memo } from 'react';
import DashboardLayout from '../../../components/DashboardLayout';
import DashboardContentLayout from '../../../components/DashboardContentLayout';
import AddChildContent from '../../../components/AddChildContent';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addChild } from '../../../redux/actions';
import Loader from '../../../components/Loader';
import Typography from '@material-ui/core/Typography';

export const genderOptions = ['male', 'female'];

const AddChild = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const breadcrumbLinks = [{ route: '/children', title: 'Children' }];
  const [parentId, setParentId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [medicalCondition, setMedicalCondition] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  // const [admin, setAdmin] = useState(false);

  const clearAllFields = () => {
    setParentId('');
    setFirstName('');
    setLastName('');
    setMedicalCondition('');
    setAge('');
    setGender('female');
  };

  const handleAddChild = () => {
    dispatch(
      addChild({
        firstName,
        lastName,
        gender,
        age,
        medicalCondition,
        parentId,
      })
    );
  };

  return (
    <DashboardLayout
      breadcrumbLinks={breadcrumbLinks}
      currentPage={'Add Child'}
    >
      <ToastContainer />
      <AddChildContent
        child={{
          firstName,
          lastName,
          parentId,
          medicalCondition,
          age,
          gender,
        }}
        genderOptions={genderOptions}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setAge={setAge}
        setParentId={setParentId}
        setGender={setGender}
        setMedicalCondition={setMedicalCondition}
        handleAddChild={handleAddChild}
        handleCancelAddingChild={clearAllFields}
      />
    </DashboardLayout>
  );
};

export default memo(AddChild);

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../../utils';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getToken();

  return (
    <Route
      {...rest}
      render={props => {
        return token ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    ></Route>
  );
};

export default PrivateRoute;

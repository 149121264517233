import React, { memo } from 'react';
import DashboardContentLayout from '../DashboardContentLayout';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  buttonBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: '200px',
    '& > :first-child': {
      margin: '0 0 20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      maxWidth: '300px',
      '& > :first-child': {
        margin: '0 20px 0 0 ',
        maxWidth: '300px',
      },
    },
  },
  textFieldWrapper: {
    paddingBottom: theme.spacing(2),
  },
}));

const AddChildContent = ({
  child,
  setFirstName,
  setLastName,
  setAge,
  setGender,
  setMedicalCondition,
  setParentId,
  genderOptions,
  handleAddChild,
  handleCancelAddingChild,
}) => {
  const classes = useStyles();
  const {
    firstName,
    lastName,
    age,
    gender,
    medicalCondition,
    parentId,
  } = child;

  return (
    <DashboardContentLayout>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={8}>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              label="Parent ID"
              required={true}
              onChange={e => setParentId(e.target.value)}
              variant="outlined"
              value={parentId}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              label="First Name"
              required={true}
              onChange={e => setFirstName(e.target.value)}
              variant="outlined"
              value={firstName}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              label="Last Name"
              required={true}
              onChange={e => setLastName(e.target.value)}
              variant="outlined"
              value={lastName}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              required={true}
              type="number"
              label="Age"
              onChange={e => setAge(e.target.value)}
              variant="outlined"
              value={age}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              required={true}
              label="Medical Condition"
              variant="outlined"
              onChange={e => setMedicalCondition(e.target.value)}
              value={medicalCondition}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <Autocomplete
              fullWidth
              variant="outlined"
              getOptionLabel={option => option}
              onChange={(e, v) => (!v ? null : setGender(v.toLowerCase()))}
              value={gender}
              renderInput={params => (
                <TextField {...params} label="Gender" variant="outlined" />
              )}
              options={genderOptions}
            />
          </Grid>
          {/* <Grid item xs={12} className={classes.textFieldWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={admin}
                  disabled={true}
                  // onChange={e => setAdmin(prevState => !prevState)}
                  color="primary"
                />
              }
              label="Admin"
            />
          </Grid> */}
        </Grid>
        <Grid item xs={12} sm={4} className={classes.buttonBlock}>
          <Button
            variant="contained"
            fullWidth
            style={{ backgroundColor: '#1D7A69', color: 'white' }}
            onClick={handleCancelAddingChild}
          >
            {' '}
            Clear Fields
          </Button>
          <Button
            fullWidth
            variant="contained"
            style={{ backgroundColor: '#275262', color: 'white' }}
            onClick={handleAddChild}
          >
            Add Child
          </Button>
        </Grid>
      </Grid>
    </DashboardContentLayout>
  );
};

export default memo(AddChildContent);

import React, { memo } from 'react';
import DashboardContentLayout from '../DashboardContentLayout';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ChildrenList from './ChildrenList';

const useStyles = makeStyles(theme => ({
  buttonBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: '200px',
    '& > :first-child': {
      margin: '0 0 20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      '& > :first-child': {
        margin: '0 20px 0 0 ',
        maxWidth: '300px',
      },
    },
  },
  textFieldWrapper: {
    paddingBottom: theme.spacing(2),
  },
}));

const ParentPageContent = ({
  parent,
  setName,
  setCity,
  setEmail,
  setCountry,
  setAdmin,
  setEditingParent,
  setAddressOne,
  setAddressTwo,
  addChildren,
  editingParent,
  handleUpdateParent,
}) => {
  const classes = useStyles();
  const {
    name,
    city,
    country,
    admin,
    email,
    addressOne,
    addressTwo,
    children,
  } = parent;

  return (
    <>
      <DashboardContentLayout>
        <Grid container spacing={5}>
          <Grid container item xs={12} sm={8}>
            <Grid item xs={12} className={classes.textFieldWrapper}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                onChange={e => setName(e.target.value)}
                variant="outlined"
                disabled={!editingParent}
                value={name}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldWrapper}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
                disabled={!editingParent}
                value={email}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldWrapper}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="City"
                variant="outlined"
                disabled={!editingParent}
                onChange={e => setCity(e.target.value)}
                value={city}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldWrapper}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Country"
                disabled={!editingParent}
                variant="outlined"
                onChange={e => setCountry(e.target.value)}
                value={country}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldWrapper}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Address Line 1"
                disabled={!editingParent}
                variant="outlined"
                onChange={e => setAddressOne(e.target.value)}
                value={addressOne}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldWrapper}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Address Line 2"
                disabled={!editingParent}
                variant="outlined"
                onChange={e => setAddressTwo(e.target.value)}
                value={addressTwo}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldWrapper}>
              <FormControlLabel
                control={
                  <Checkbox checked={admin} disabled={true} color="primary" />
                }
                label="Admin"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.buttonBlock}>
            <Button
              variant="contained"
              fullWidth
              style={{ backgroundColor: '#1D7A69', color: 'white' }}
              onClick={e => setEditingParent(prevState => !prevState)}
            >
              {editingParent ? 'Cancel' : 'Edit parent'}
            </Button>
            {editingParent && (
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: '#275262', color: 'white' }}
                onClick={handleUpdateParent}
              >
                Save
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {children && children.length ? (
              <ChildrenList children={children} />
            ) : null}
          </Grid>
        </Grid>
      </DashboardContentLayout>
    </>
  );
};

export default memo(ParentPageContent);

import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllChildren } from '../../redux/actions';
import Loader from '../../components/Loader';
import DashboardLayout from '../../components/DashboardLayout';
import DashboardContentLayout from '../../components/DashboardContentLayout';
import ChildrenContent from '../../components/ChildrenContent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChildrenPage = () => {
  const dispatch = useDispatch();
  const children = useSelector(store => store.apiReducer.children);

  useEffect(() => {
    if (!children) {
      dispatch(getAllChildren());
    }
  }, []);

  if (!children) {
    return (
      <DashboardLayout breadcrumbLinks={null} currentPage={'Children'}>
        <ToastContainer />
        <DashboardContentLayout>
          <Loader />
        </DashboardContentLayout>
      </DashboardLayout>
    );
  } else {
    return (
      <div>
        <DashboardLayout breadcrumbLinks={null} currentPage={'Children'}>
          <DashboardContentLayout>
            <ChildrenContent children={children} />
          </DashboardContentLayout>
        </DashboardLayout>
      </div>
    );
  }
};

export default memo(ChildrenPage);

import axios from 'axios';
import {
  TOKEN_OBTAINED,
  LOG_OUT,
  GET_PARENTS,
  GET_CHILDREN_FOR_PARENT,
  GET_CHILD_STORY_LOG,
  GET_CHILD_STARS_LOG,
  FIND_PARENT,
  UPDATE_PARENT,
  UPDATE_CHILD,
  SET_CURRENT_PAGE,
  SET_CURRENT_TABLE,
  GET_PARENTS_REPORT,
  GET_CHILDREN,
} from './types';
import { getToken } from '../utils';
import { toast } from 'react-toastify';
import { prettifyDate } from '../utils';

export const getParentsDataFromAPIResponse = response => {
  return response.data.data;
};

export const modifyParentDataFromTheApi = parent => {
  return {
    name: parent.name,
    city: parent.address_city,
    email: parent.email,
    id: parent.ID,
    isOrganization: parent.is_org,
    address1: parent.address_1,
    address2: parent.address_2,
    country: parent.address_countryprov,
    postcode: parent.postcode,
    created: parent.created ? prettifyDate(parent.created) : '',
    isAdmin: parent.is_admin,
  };
};

export const modifyChildrenDataFromTheApi = child => {
  const created = { created: prettifyDate(child.created) };
  const lastModified = { last_modified: prettifyDate(child.last_modified) };
  return {
    ...child,
    ...created,
    ...lastModified,
  };
};

const baseApiURL =
  window.location.hostname === '127.0.0.1'
    ? 'http://localhost:3000'
    : 'https://api.zimizam.com';

export const loginUser = (email, pass) => async dispatch => {
  try {
    let token;
    const formData = new FormData();
    formData.set('email', email);
    formData.set('password', pass);
    const serializedFormData = new URLSearchParams(formData).toString();
    const response = await axios.post(
      baseApiURL + '/v1/admin/sign-in',
      serializedFormData
    );

    if (response.success || response.data.success) {
      token = response.data.data.token;
      localStorage.setItem('token', token);

      dispatch({
        type: TOKEN_OBTAINED,
        payload: token,
      });
    } else if (!response.success || !response.data.success) {
      throw new Error('Log in failed');
    }
  } catch (err) {
    console.error(err);
    throw new Error('Log in failed');
  }
};

export const logOut = () => {
  console.log('logout');
  return dispatch => {
    localStorage.removeItem('token');
    dispatch({
      type: LOG_OUT,
    });
  };
};

export const getAllParents = () => async dispatch => {
  const token = getToken();
  const config = {
    headers: { Authorization: 'bearer ' + token },
  };
  const response = await axios.get(baseApiURL + '/v1/admin/parent/all', config);
  // add error handler
  const parentsDataForStore = getParentsDataFromAPIResponse(
    response
  ).map(parentData => modifyParentDataFromTheApi(parentData));
  dispatch({
    type: GET_PARENTS,
    payload: parentsDataForStore,
  });
};

export const getAllChildren = () => async dispatch => {
  const token = getToken();
  const config = {
    headers: { Authorization: 'bearer ' + token },
  };
  const response = await axios.get(baseApiURL + '/v1/admin/child/all', config);
  dispatch({
    type: GET_CHILDREN,
    payload: response.data.data.map(child =>
      modifyChildrenDataFromTheApi(child)
    ),
  });
};

export const getChildrenForParent = parentId => async (dispatch, getState) => {
  // prevent unnecessary requests
  if (!parentId) return;
  const selectedParent = getState().apiReducer.parents.filter(
    parent => parent.id == parentId
  )[0];
  if (selectedParent) {
    if (selectedParent.children) {
      return selectedParent.children;
    }
  }

  const token = getToken();
  const config = {
    headers: { Authorization: 'bearer ' + token },
  };
  const response = await axios.get(
    `${baseApiURL}/v1/parent/${parentId}/children`,
    config
  );
  if (response.data.data.children.length) {
    dispatch({
      type: GET_CHILDREN_FOR_PARENT,
      parentId,
      children: response.data.data.children,
    });
  } else {
    dispatch({
      type: GET_CHILDREN_FOR_PARENT,
      parentId,
      children: [],
    });
  }
  return response.data.data.children;
};

export const getChildStoryLog = id => async (dispatch, getState) => {
  // prevent unnecessary requests
  const selectedChild = getState().apiReducer.children.filter(
    child => child.id == id
  );
  if (selectedChild.history) {
    return;
  }
  const token = getToken();
  const config = {
    headers: { Authorization: 'bearer ' + token },
  };
  const response = await axios.get(
    baseApiURL + '/v1/child/' + id + '/storyhistory',
    config
  );
  if (response.data.data) {
    dispatch({
      type: GET_CHILD_STORY_LOG,
      storyLog: response.data.data,
      childId: id,
    });
  } else {
    dispatch({
      type: GET_CHILD_STORY_LOG,
      storyLog: {},
      childId: id,
    });
  }
};

export const getChildStarsLog = id => async (dispatch, getState) => {
  // prevent unnecessary requests
  const selectedChild = getState().apiReducer.children.filter(
    child => child.id == id
  );
  if (selectedChild.stars) {
    return;
  }
  const token = getToken();
  const config = {
    headers: { Authorization: 'bearer ' + token },
  };
  const response = await axios.get(
    baseApiURL + '/v1/child/' + id + '/stars',
    config
  );
  if (response.data.data) {
    dispatch({
      type: GET_CHILD_STARS_LOG,
      stars: response.data.data,
      childId: id,
    });
  } else {
    dispatch({
      type: GET_CHILD_STARS_LOG,
      stars: {},
      childId: id,
    });
  }
};

export const updateParent = (id, parent) => async dispatch => {
  const token = getToken();
  const formData = new FormData();
  formData.set('name', parent.name);
  formData.set('email', parent.email);
  formData.set('id', parent.id);
  formData.set('address_city', parent.city);
  formData.set('address_countryprov', parent.country);
  formData.set('address_1', parent.addressOne);
  formData.set('address_2', parent.addressTwo);
  const serializedFormData = new URLSearchParams(formData).toString();
  const response = await axios({
    method: 'put',
    url: `${baseApiURL}/v1/parent/${id}`,
    data: serializedFormData,
    headers: { Authorization: 'bearer ' + token },
  });
  if (response.data.success) {
    dispatch(getAllParents());
    toast('Successfully updated parent!');
  } else {
    toast("Couldn't update parent");
  }
};

export const updateChild = (id, child) => async dispatch => {
  console.log(child);
  const token = getToken();
  const formData = new FormData();
  formData.set('first_name', child.firstName);
  formData.set('last_name', child.lastName);
  formData.set('detail_id', id);
  formData.set('parent_id', child.parentId);
  formData.set('age', child.age);
  formData.set('gender', child.gender);
  const serializedFormData = new URLSearchParams(formData).toString();
  const response = await axios({
    method: 'put',
    url: `${baseApiURL}/v1/child/${id}`,
    data: serializedFormData,
    headers: { Authorization: 'bearer ' + token },
  });
  if (response.data.success) {
    toast('Successfully updated child data!');
  } else {
    toast("Couldn't update child data");
  }
};

export const findParent = (parentId, childId) => async (dispatch, getState) => {
  // prevent unnecessary requests
  const selectedChild = getState().apiReducer.children.filter(
    child => child.id == childId
  );
  if (selectedChild.parentData) {
    return;
  }
  const token = getToken();
  const config = {
    headers: { Authorization: 'bearer ' + token },
  };
  const response = await axios.get(
    baseApiURL + `/v1/parent/${parentId}`,
    config
  );
  if (response.data) {
    dispatch({
      type: FIND_PARENT,
      parentData: response.data.data,
      childId: childId,
    });
  } else {
    dispatch({
      type: FIND_PARENT,
      parentData: {},
      childId: childId,
    });
  }
};

export const totalParentsReport = () => async dispatch => {
  const token = getToken();
  const config = {
    headers: { Authorization: 'bearer ' + token },
  };
  const report = await axios.get(
    baseApiURL + '/v1/admin/reports/parents/total',
    config
  );

  dispatch({
    type: GET_PARENTS_REPORT,
    payload: report,
  });
};

export const addParent = parent => async dispatch => {
  const formData = new FormData();
  formData.set('name', parent.name);
  formData.set('email', parent.email);
  formData.set('password', parent.pass);
  formData.set('address_1', parent.addressOne);
  formData.set('address_2', parent.addressTwo);
  formData.set('address_countryprov', parent.country);
  formData.set('address_city', parent.city);
  const serializedFormData = new URLSearchParams(formData).toString();
  const response = await axios.post(
    baseApiURL + '/v1/sign-up',
    serializedFormData
  );

  if (response.data.success) {
    dispatch(getAllParents());
    toast('Parent added');
  } else {
    toast("Parent couldn't be added");
  }
};

export const addChild = child => async dispatch => {
  const token = getToken();
  const formData = new FormData();
  formData.set('first_name', child.firstName);
  formData.set('last_name', child.lastName);
  formData.set('medical_condtion', child.medicalCondition);
  formData.set('gender', child.gender);
  formData.set('age', child.age);
  formData.set('parent_id', child.parentId);
  const serializedFormData = new URLSearchParams(formData).toString();
  const response = await axios({
    method: 'post',
    url: `${baseApiURL}/v1/child`,
    data: serializedFormData,
    headers: { Authorization: 'bearer ' + token },
  });

  axios.post(baseApiURL + '/v1/sign-up', serializedFormData);

  if (response.data.success) {
    dispatch(getAllChildren());
    toast('Child added!');
  } else {
    toast("Child couldn't be added");
  }
};

// TABLE
export const setCurrentPage = page => dispatch => {
  return dispatch({
    type: SET_CURRENT_PAGE,
    payload: page + 1,
  });
};

export const setCurrentTable = table => dispatch => {
  return dispatch({
    type: SET_CURRENT_TABLE,
    payload: table,
  });
};

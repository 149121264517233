export const TOKEN_OBTAINED = 'TOKEN_OBTAINED';
export const LOG_OUT = 'LOG_OUT';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const TOGGLE_DESKTOP_MENU = 'TOGGLE_DESKTOP_MENU';
export const GET_PARENTS = 'GET_PARENTS';
export const GET_CHILDREN = 'GET_CHILDREN';
export const GET_PARENTS_REPORT = 'GET_PARENTS_REPORT';
export const GET_CHILDREN_FOR_PARENT = 'GET_CHILDREN_FOR_PARENT';
export const GET_CHILD_STORY_LOG = 'GET_CHILD_STORY_LOG';
export const GET_CHILD_STARS_LOG = 'GET_CHILD_STARS_LOG';
export const FIND_PARENT = 'FIND_PARENT';
export const UPDATE_PARENT = 'UPDATE_PARENT';
export const ADD_PARENT = 'ADD_PARENT';
export const ADD_CHILD = 'ADD_CHILD';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_CURRENT_TABLE = 'SET_CURRENT_TABLE';

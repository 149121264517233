import React, { useState, useEffect, memo } from 'react';
import DashboardLayout from '../../../components/DashboardLayout';
import DashboardContentLayout from '../../../components/DashboardContentLayout';
import ParentPageContent from '../../../components/ParentPageContent';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllParents,
  getChildrenForParent,
  updateParent,
} from '../../../redux/actions';
import Loader from '../../../components/Loader';
import Typography from '@material-ui/core/Typography';

export const findParentData = (parents, id) => {
  const parent = parents.filter(parent => parent.id === parseInt(id))[0];
  return parent;
};

const ParentPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedParentId = pathname.split('/parents/')[1];
  const parents = useSelector(store => store.apiReducer.parents);
  const [selectedParent, setSelectedParent] = useState(null);
  const [children, setChildren] = useState(null);
  const breadcrumbLinks = [{ route: '/parents', title: 'Parents' }];
  const [editingParent, setEditingParent] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [country, setCountry] = useState('');
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (!parents) {
      dispatch(getAllParents());
      return;
    }

    if (parents.length) {
      setSelectedParent(findParentData(parents, selectedParentId));
    }
  }, [parents, selectedParentId]);

  useEffect(() => {
    if (children === null && parents !== null && selectedParentId) {
      dispatch(getChildrenForParent(selectedParentId)).then(res => {
        setChildren(prevState => res);
      });
    }
  }, [parents]);

  useEffect(() => {
    if (selectedParent) {
      setName(selectedParent.name || '');
      setEmail(selectedParent.email || '');
      setCity(selectedParent.city || '');
      setCountry(selectedParent.country || '');
      setAddressOne(selectedParent.address1 || '');
      setAddressTwo(selectedParent.address2 || '');
      setAdmin(Boolean(selectedParent.isAdmin));
      if (selectedParent.children) {
        setChildren(selectedParent.children);
      }
    }
  }, [selectedParent]);

  if (!parents || !selectedParent || !selectedParentId) {
    return (
      <DashboardLayout breadcrumbLinks={breadcrumbLinks} currentPage="Parent">
        <Loader />
      </DashboardLayout>
    );
  }

  const handleUpdateParent = () => {
    dispatch(
      updateParent(selectedParentId, {
        id: selectedParentId,
        name,
        addressOne,
        addressTwo,
        city,
        email,
        country,
      })
    );
  };

  return (
    <DashboardLayout
      breadcrumbLinks={breadcrumbLinks}
      currentPage={selectedParent.name}
    >
      <ToastContainer />
      <ParentPageContent
        parent={{
          name,
          city,
          country,
          email,
          admin,
          addressOne,
          addressTwo,
          children,
        }}
        setEmail={setEmail}
        setName={setName}
        setChildren={setChildren}
        setCity={setCity}
        setAdmin={setAdmin}
        setCountry={setCountry}
        setAddressOne={setAddressOne}
        setAddressTwo={setAddressTwo}
        setEditingParent={setEditingParent}
        editingParent={editingParent}
        handleUpdateParent={handleUpdateParent}
      />
    </DashboardLayout>
  );
};

export default memo(ParentPage);

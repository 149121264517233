import React, { memo } from 'react';
import DashboardContentLayout from '../DashboardContentLayout';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  buttonBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: '200px',
    '& > :first-child': {
      margin: '0 0 20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      '& > :first-child': {
        margin: '0 20px 0 0 ',
      },
    },
  },
  starsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: '5px',
    },
  },
  starImage: {
    width: '40px',
    objectFit: 'contain',
  },
  textFieldWrapper: {
    paddingBottom: theme.spacing(2),
  },
}));

const ChildPageContent = ({
  child,
  genderOptions,
  setFirstName,
  setLastName,
  setMedicalCondition,
  setParentId,
  setGender,
  setAge,
  setEditingChild,
  editingChild,
  totalStarsEarned,
  averageGameDuration,
  lastTimeModified,
  handleUpdateChild,
  stars,
}) => {
  const classes = useStyles();
  const { firstName, lastName, gender, age, parentId, parentData } = child;

  return (
    <DashboardContentLayout>
      <Grid container spacing={5}>
        <Grid container item xs={12} sm={8}>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <Link
              to={`/parents/${parentId}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography style={{ color: '#275262' }}>
                Parent ID:{parentId}{' '}
                {parentData.name ? `(${parentData.name.trim()})` : null}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              label="First Name"
              onChange={e => setFirstName(e.target.value)}
              variant="outlined"
              disabled={!editingChild}
              value={firstName}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              label="Last Name"
              onChange={e => setLastName(e.target.value)}
              variant="outlined"
              disabled={!editingChild}
              value={lastName}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <TextField
              fullWidth
              type="number"
              label="Age"
              variant="outlined"
              disabled={!editingChild}
              onChange={e => setAge(e.target.value)}
              value={age}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <Autocomplete
              fullWidth
              required={true}
              disabled={!editingChild}
              variant="outlined"
              getOptionLabel={option => option}
              onChange={(e, v) => {
                if (v) {
                  setGender(v.toLowerCase());
                }
              }}
              value={gender}
              renderInput={params => (
                <TextField {...params} label="Gender" variant="outlined" />
              )}
              options={genderOptions}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <Typography>Last time modified: {lastTimeModified}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <Typography>
              Average game duration:{' '}
              {averageGameDuration ? averageGameDuration : 'not available'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textFieldWrapper}>
            <Typography>
              Total stars earned: {totalStarsEarned ? totalStarsEarned : 0}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.starsWrapper}>
            {stars &&
              stars.map((star, i) => (
                <>
                  <div className={classes.starWrapper}>
                    <img
                      className={classes.starImage}
                      src={star.img}
                      key={i}
                      alt="star"
                    />
                    <p key={star.img} className={classes.starTotal}>
                      {star.total === 1
                        ? star.total + ' star'
                        : star.total + ' stars'}
                    </p>
                  </div>
                </>
              ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} className={classes.buttonBlock}>
          <Button
            variant="contained"
            fullWidth
            style={{ backgroundColor: '#1D7A69', color: 'white' }}
            onClick={e => setEditingChild(prevState => !prevState)}
          >
            {editingChild ? 'Cancel' : 'Edit child'}
          </Button>
          {editingChild && (
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: '#275262', color: 'white' }}
              onClick={handleUpdateChild}
            >
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    </DashboardContentLayout>
  );
};

export default memo(ChildPageContent);
